<template>
  <footer>
    <div class="showOnDesktop">

        <section>
          <article class="acacia-container">
            <div class="footer-grid">
              <div>
                <div class="footer-gallery-grid">
                  <div>
                    <img src="../../assets/images/acacia_mweya1.jpg" alt="">
                  </div>
                  <div>
                    <img src="../../assets/images/acacia_mweya10.jpg" alt="">
                  </div>
                  <div>
                    <img src="../../assets/images/acacia_mweya2.jpeg" alt="">
                  </div>
                  <div>
                    <img src="../../assets/images/acacia_mweya22.jpg" alt="">
                  </div>
                  <div>
                    <img src="../../assets/images/acacia_mweya3.jpg" alt="">
                  </div>
                  <div>
                    <img src="../../assets/images/acacia_mweya4.jpeg" alt="">
                  </div>
                </div>
                <div>
                  <div>
                    <small>copyright &copy; 2024 Acacia Wilderness Mweya | All Rights Reserved</small>
                  </div>
                  <div>
              <small>
                Design by <a href="https://www.ovalspace.co" target="_blank">Ovalspace</a>
              </small>
            </div>
                </div>
              </div>
              <div>
                <h2>Stay Connected</h2>
                <p>Subscribe to our news letter with stories from our latest adventures and the travel tips</p>
                <el-form :model="numberValidateForm" ref="numberValidateForm" label-position="top" label-width="100px" class="demo-ruleForm">
                  <el-form-item
                    prop="age"
                    :rules="[
                      { required: true, message: 'Email is required'},
                      { type: 'email', message: 'Enter a valid email'}
                    ]"
                  >
                    <el-input v-model.number="numberValidateForm.age" autocomplete="off" placeholder="Email"></el-input>
                  </el-form-item>
                  <el-form-item>
                    <el-button type="primary" class="acacia-btn mt-2 mb-2" @click="submitForm('numberValidateForm')">Subscribe</el-button>
                  </el-form-item>
                </el-form>
                <el-button type="primary" class="acacia-btn-inverse">Download Our Profile</el-button>
              </div>
              <div>
                <h2>Contact Us</h2>
                <ul>
                  <li>
                    <i class="fas fa-phone" style="color: #d96b12; padding-right:5px"></i>
                    +256 700 817 268
                  </li>
                  <li>
                    <a href="tel:+256783377001">
                    <i class="fas fa-phone" style="color: #d96b12; padding-right:5px"></i>
                    +256 765 708 329
                    </a>
                  </li>
                  <li>
                    <i class="far fa-envelope" style="color: #d96b12; padding-right:5px"></i>
                    info@acaciawildernessmweya.com
                  </li>
                  <li>
                    <i class="far fa-envelope" style="color: #d96b12; padding-right:5px"></i>
                    acaciawildernessmweya@gmail.com
                  </li>
                  <li class="social-medias-grid">
                    <div class="mr-2">
                      <a href="http://www.twitter.com/acaciamweya"><i class="fab fa-facebook"></i></a>
                    </div>
                    <div class="mr-2">
                      <a href="https://www.facebook.com/"><i class="fab fa-instagram"></i></a>
                    </div>
                    <div class="mr-2">
                      <a href="https://www.instagram.com/acacia_wilderness_mweya?igsh=MXkwbGFkODYzajZuYQ%3D%3D&utm_source=qr">
                        <i class="fab fa-twitter"></i>
                      </a>
                    </div>
                  </li>
                </ul>
                <!-- <div>
                  https://snapchat.com/t/TbhecWBI
                  https://www.tiktok.com/@acacia.wilderness?_t=8ohmtSoEFaX&_r=1
                  acaciawildernessmweya@gmail.com
                  <el-button type="primary" class="acacia-btn-inverse">Download Our Fact Sheet</el-button>
                </div> -->
              </div>
            </div>
          </article>
        </section>
    </div>




    <div class="showOnMobile">
      <div class="acacia-container">
        <el-collapse v-model="activeNames" @change="handleChange">

          <el-collapse-item title="Web Menu" name="1">
            <div>
              <ul>
                <li @click="$router.push({ path: '/' })">About Us</li>
                <li @click="$router.push({ path: '/' })">Accomodation</li>
                <li @click="$router.push({ path: '/' })">Dining</li>
                <li @click="$router.push({ path: '/' })">Blog</li>
              </ul>
            </div>
          </el-collapse-item>

          <el-collapse-item title="Accomodation" name="2">
            <div>
              <ul>
                <li @click="$router.push({ path: '/' })">Single Occupancy</li>
                <li @click="$router.push({ path: '/' })">Double Occupancy</li>
                <li @click="$router.push({ path: '/' })">Family Occupancy</li>
              </ul>
            </div>
          </el-collapse-item>

          <el-collapse-item title="Contact Menu" name="3">
             <div>
                <ul>
                  <li @click="$router.push({ path: '/' })">Contact Us</li>
                  <li @click="$router.push({ path: '/' })">Gallery</li>
                  <li @click="$router.push({ path: '/' })">Community Impact</li>
                </ul>
            </div>
          </el-collapse-item>

          <el-collapse-item title="Contacts" name="5">
           <ul>
                  <li>
                    <a href="mailto:info@acaciawildernessmweya.com">
                    <i class="far fa-envelope" style="color: #d96b12; padding-right:5px"></i>
                    info@acaciawildernessmweya.com
                     </a>
                  </li> <li>
                    <i class="fas fa-phone" style="color: #d96b12; padding-right:5px"></i>
                    +256 765 708 329
                  </li>
                  <li>
                    <a href="tel:+256783377001">
                    <i class="fas fa-phone" style="color: #d96b12; padding-right:5px"></i>
                    +256 765 708 329
                    </a>
                  </li>
                  <li>
                    <i class="fas fa-phone" style="color: #d96b12; padding-right:5px"></i>
                    +256 765 708 329
                  </li>
                
                </ul>
        </el-collapse-item>

        </el-collapse>
        
        <div class="mt-3" style="color: white; display: flex; font-size: 1.2em">
          <div class="mr-2">
                      <a href="https://twitter.com/"><i class="fab fa-facebook"></i></a>
                    </div>
                    <div class="mr-2">
                      <a href="https://www.facebook.com/"><i class="fab fa-instagram"></i></a>
                    </div>
                    <div class="mr-2">
                      <a href="https://www.instagram.com/"><i class="fab fa-twitter"></i></a>
                    </div>
                  </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      activeNames: ['1'],
      numberValidateForm: {
        age: ''
      }
    };
  },

  methods: {
      handleChange(val) {
        console.log(val);
      },
      
      submitForm(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            alert('submit!');
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      },
      resetForm(formName) {
        this.$refs[formName].resetFields();
      }
  },
};
</script>

<style scoped>
.acacia-container {
  width: 100%;
  padding-left: 8%;
  padding-right: 8%;
}

.footer h2 {
  font-size: 2em;
  color: #17351E !important;
  font-weight: 800;
}
footer {
  background-color: #DDD0A9;
  padding-top: 50px;
  padding-bottom: 50px;
}

.footer-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}
.footer-grid >div{
  height: 400px;
  padding-right: 50px;
}
.footer-grid >div:nth-child(3){
  border-left: 1px dotted black;
  padding-left: 50px;
}

.footer-gallery-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 10px;
}

.footer-gallery-grid >div{
  height: 100px;
  width: 100%;
  background-color: rgb(172, 168, 168);
}

.footer-gallery-grid >div img{
  height: 100px;
  width: 100%;
  object-fit: cover;
}


.acacia-btn-inverse {
  background-color: #17351E;
  color: white !important;
  border: 2px solid #17351E;
  border-radius: 0px;
  font-size: 1.2em;
  margin: 0px !important;
  transition: 0.5s;
  width: 100%;
}
.acacia-btn-inverse:hover {
  background-color: transparent;
  color: #17351E;
  transition: 0.5s;
}

.acacia-btn {
  background-color: transparent;
  color: #17351E;
  border: 2px solid #17351E;
  border-radius: 0px;
  font-size: 1.2em;
  margin: 0px !important;
  transition: 0.5s;
  width: 100%;
}
.acacia-btn:hover {
  background-color: #17351E;
  color: white !important;
  transition: 0.5s;
}

.social-medias-grid {
  display: flex;
  align-items: center;
}

.social-medias-grid >div{
  width: 50px;
  height: 50px;
  border: 2px solid #17351E;
  border-radius: 50%;
  color: #17351E !important;
  font-size: 1.5em;
  transition: 1s;

  display: flex;
  align-items: center;
  justify-content: center;
}
.social-medias-grid >div a{
  color: #17351E !important;
}


.social-medias-grid >div:hover{
  background-color:#17351E;
  transition: 1s;
}
.social-medias-grid >div:hover a{
  color: #FFF !important;
}

a{
  color:gray;
}
footer ul {
  list-style: none;
  padding: 0px;
  margin: 20px 0px 0px 0px;
  text-align: left;
}

footer ul li {
  margin-bottom: 15px;
  cursor: pointer;
}
.nav-flex {
  width: 100%;
  display: flex;
  /* align-items: center; */
  justify-content: space-between;
}
.nav-flex > div {
}
.nav-flex > div:first-child,
.nav-flex > div:last-child {
  width: 100%;
}

.nav-flex .nav-list-one {
  list-style: none;
  width: 100%;
  height: 100%;
  padding: 0px;
  display: flex;
  align-items: center;
}

.nav-flex .nav-list-two {
  list-style: none;
  width: 100%;
  height: 100%;
  padding: 0px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.nav-flex div {
  font-size: 0.9em;
  font-weight: 400;
}

.brand-name {
  margin-left: 50px;
  margin-right: 50px;
}
.col-title {
  color: white;
  font-weight: 500;
  font-size: 1.2em;
  margin-bottom: 50px;
}

.showOnDesktop {
  display: block;
}

.showOnMobile {
  display: none;
}

/*##Device = Tablets, Ipads (portrait)##Screen = B/w 768px to 1024px*/
@media (min-width: 768px) and (max-width: 1024px) {
  
  .showOnDesktop {
    display: none;
  }

  .showOnMobile {
    display: block;
  }

}

/*##Device = Tablets, Ipads (landscape)##Screen = B/w 768px to 1024px*/
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  
  .showOnDesktop {
    display: none;
  }

  .showOnMobile {
    display: block;
  }

}

/*##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px*/
@media (min-width: 481px) and (max-width: 767px) {
  .acacia-container {
    padding-left: 1%;
    padding-right: 1%;
  }

  .showOnDesktop {
    display: none;
  }

  .showOnMobile {
    display: block;
  }

}

/*##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px*/
@media (min-width: 320px) and (max-width: 480px) {
  .acacia-container {
    padding-left: 1%;
    padding-right: 1%;
  }
  .nav-flex {
    display: block;
  }
  .brand-name {
    display: none;
  }

  .showOnDesktop {
    display: none;
  }

  .showOnMobile {
    display: block;
  }
}
</style>

<style>
  footer .el-collapse-item__header {
    background-color: #DDD0A9;
    border-bottom: 1px solid #EBEEF5;
    color: #FEF9F6;
    text-align: left;
  }
  footer .el-collapse-item {
    background-color: #DDD0A9;
    color: #eeeeee;
    text-align: left;
  }
  footer .el-collapse-item__content {
    border: none;
    background-color: #DDD0A9;
    color: #eeeeee;
    text-align: left;
  }
  footer .el-collapse-item__wrap {
      will-change: height;
      background-color: #DDD0A9;
      overflow: hidden;
      box-sizing: border-box;
      border-bottom: 1px solid #EBEEF5;
  }

  footer .el-input__inner {
    -webkit-appearance: none;
    background-color: #FFF;
    background-image: none;
    border-radius: 0px;
    border: 2px solid #17351E;
    box-sizing: border-box;
    color: #606266;
    display: inline-block;
    font-size: inherit;
    height: 50px;
    line-height: 40px;
    outline: 0;
    padding: 0 15px;
    transition: border-color .2s cubic-bezier(.645,.045,.355,1);
    width: 100%;
}
</style>